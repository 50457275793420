import Papa from 'papaparse';
const NO_SPACE_REGEX = /[\u200B-\u200D\uFEFF]/g;
// regex to check for formula injection
const FORMULA_INJECTION_REGEX = /^[=@+-]/;

export const fieldNamesAdminUsers = {
	email: "email",
	phone: "phone",
	firstName: "firstName",
	lastName: "lastName",
	siteId: "siteId",
};

function getColumnIndex(header: string[]) {
	return function (...columnKeys: string[]) {
		return header.findIndex((column: string) => {
			const trimmedColumn = column.replace(NO_SPACE_REGEX, '');
			return columnKeys.includes(trimmedColumn);
		});
	};
}

function getImportantColumnIndices(header: string[]) {
	const getIndexFromHeader = getColumnIndex(header);
	if (Array.isArray(header)) {
		return {
			email: getIndexFromHeader(`${fieldNamesAdminUsers.email}`),
			phone: getIndexFromHeader(`${fieldNamesAdminUsers.phone}`),
			firstName: getIndexFromHeader(`${fieldNamesAdminUsers.firstName}`),
			lastName: getIndexFromHeader(`${fieldNamesAdminUsers.lastName}`),
			siteId: getIndexFromHeader(`${fieldNamesAdminUsers.siteId}`),
		};
	}

	return {};
}

// internalId	protocol	siteName	siteId	country	piFirstName	piLastName	piEmail
function getImportantColumnIndicesLocations(header: string[]) {
	const getIndexFromHeader = getColumnIndex(header);
	if (Array.isArray(header)) {
		return {
			internalId: getIndexFromHeader('internalId'),
			protocol: getIndexFromHeader('protocol'),
			siteName: getIndexFromHeader('siteName'),
			siteId: getIndexFromHeader('siteId'),
			country: getIndexFromHeader('country'),
		};
	}
	return {};
}

function mapStringField(row: any, fieldIndex: number) {
	const value = Object.values(row)[fieldIndex];
	// if the value is a string and contains a formula injection, wrap it in single quotes
	if (typeof value === 'string' && FORMULA_INJECTION_REGEX.test(value)) {
		return `'${value}`;
	}
	return value;
}

const getFieldMapper = (fieldName: string) => {
	switch (fieldName) {
		default:
			return mapStringField;
	}
};

function mapValuesAll(rows: any[], columnMappings: any) {
	return rows.map((row) => {
		return Object.fromEntries(
			Object.entries(columnMappings).map(([fieldName, fieldIndex]) => {
				const fieldMapper = getFieldMapper(fieldName);
				return [fieldName, fieldMapper(row, fieldIndex as number)];
			})
		);
	});
}

function mapValuesUsers(rows: any[], columnMappings: any) {
	// import only those with an email!
	const successRows = rows.filter((row) => {
		const withEmail = Object.values(row)[columnMappings['email']];
		return withEmail;
	});
	return mapValuesAll(successRows, columnMappings);
	// return successRows.map((row) => {
	// 	return Object.fromEntries(
	// 		Object.entries(columnMappings).map(([fieldName, fieldIndex]) => {
	// 			const fieldMapper = getFieldMapper(fieldName);
	// 			return [fieldName, fieldMapper(row, fieldIndex as number)];
	// 		})
	// 	);
	// });
}

export const parseCSV = async (file: any, fileType: "users" | "locations"): Promise<any[]> => {
	return new Promise((resolve, reject) => {
		let rows;
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			delimiter: ',',
			complete: function ({ meta, data }) {
				let columnMappings;
				let rows: any[] = [];
				if (fileType === "users") {
					columnMappings = getImportantColumnIndices(meta.fields as string[]);
					rows = mapValuesUsers(data, columnMappings);
				}
				else if (fileType === "locations") {
					columnMappings = getImportantColumnIndicesLocations(meta.fields as string[]);
					rows = mapValuesAll(data, columnMappings);
				}
				if (!columnMappings) {
					reject("No file type defined, should users or locations")
				}
				resolve(rows);
			},
		});
	});
};
